const AnimationContainer = () => {
  return (
    <div id="animationWindow">
      <div className="title">
        <h2>Mac</h2>
        <h1>StudiO's</h1>
      </div>
    </div>
  );
};
export default AnimationContainer;
