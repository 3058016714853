const VideoContainer = ({ shouldBeFixed }) => {
  return (
    <div
      id="video-bg-container"
      className={shouldBeFixed ? "" : "should-not-be-fixed"}
    >
      <video
        src="https://www.applebreakfast.com/video/apple_video.mp4"
        autoPlay
        loop
        muted
      ></video>
      <div className="text">The future of breakfast.</div>
    </div>
  );
};

export default VideoContainer;
