import { useEffect } from "react";

const Header = () => {
  useEffect(() => {
    const searchButton = document.querySelector(
      "nav .desktop-nav .link-search"
    );
    const closeButton = document.querySelector(".search-container .link-close");
    const desktopNav = document.querySelector(".desktop-nav");
    const searchContainer = document.querySelector(".search-container");
    const overlay = document.querySelector(".overlay");

    searchButton.addEventListener("click", () => {
      desktopNav.classList.add("hide");
      searchContainer.classList.remove("hide");
      overlay.classList.add("show");
    });

    closeButton.addEventListener("click", () => {
      desktopNav.classList.remove("hide");
      searchContainer.classList.add("hide");
      overlay.classList.remove("show");
    });

    overlay.addEventListener("click", () => {
      desktopNav.classList.remove("hide");
      searchContainer.classList.add("hide");
      overlay.classList.remove("show");
    });

    // Mobile Version

    const menuIconContainer = document.querySelector(
      "nav .menu-icon-container"
    );
    const navContainer = document.querySelector(".nav-container");

    menuIconContainer.addEventListener("click", () => {
      navContainer.classList.toggle("active");
    });

    const searchBar = document.querySelector(
      ".mobile-search-container .search-bar"
    );
    const nav = document.querySelector(".nav-container nav");
    const searchInput = document.querySelector(
      ".mobile-search-container input"
    );
    const cancelBtn = document.querySelector(
      ".mobile-search-container .cancel-btn"
    );

    searchInput.addEventListener("click", () => {
      searchBar.classList.add("active");
      nav.classList.add("move-up");
      desktopNav.classList.add("move-down");
    });

    cancelBtn.addEventListener("click", () => {
      searchBar.classList.remove("active");
      nav.classList.remove("move-up");
      desktopNav.classList.remove("move-down");
    });

    window.addEventListener("scroll", () => {
      const undermenu = document.getElementById("under-menu-container");
      const linkscontainer = document.getElementById("links-container");

      if (document.documentElement.scrollTop > 44) {
        undermenu.classList.add("fix-header");
        linkscontainer.classList.add("fix-links-container");
      } else {
        undermenu.classList.remove("fix-header");
        linkscontainer.classList.remove("fix-links-container");
      }
    });
  }, []);

  return (
    <header>
      <div className="nav-container">
        <nav>
          <ul className="mobile-nav">
            <li>
              <div className="menu-icon-container">
                <div className="menu-icon">
                  <span className="line-1"></span>
                  <span className="line-2"></span>
                </div>
              </div>
            </li>

            <li>
              <a
                href="https://ae.studio/"
                target="_blank"
                rel="noreferrer"
                className="link-logo"
              ></a>
            </li>

            <li>
              <a
                href="https://ae.studio/"
                target="_blank"
                rel="noreferrer"
                className="link-bag"
              ></a>
            </li>
          </ul>

          <ul className="desktop-nav">
            <li>
              <a
                href="https://ae.studio/"
                target="_blank"
                rel="noreferrer"
                className="link-logo"
              ></a>
            </li>
            <li>
              <a href="https://ae.studio/" target="_blank" rel="noreferrer">
                Mac
              </a>
            </li>
            <li>
              <a href="https://ae.studio/" target="_blank" rel="noreferrer">
                iPad
              </a>
            </li>
            <li>
              <a href="https://ae.studio/" target="_blank" rel="noreferrer">
                iPhone
              </a>
            </li>
            <li>
              <a href="https://ae.studio/" target="_blank" rel="noreferrer">
                Watch
              </a>
            </li>
            <li>
              <a href="https://ae.studio/" target="_blank" rel="noreferrer">
                TV
              </a>
            </li>
            <li>
              <a href="https://ae.studio/" target="_blank" rel="noreferrer">
                Music
              </a>
            </li>
            <li>
              <a href="https://ae.studio/" target="_blank" rel="noreferrer">
                Support
              </a>
            </li>
            <li>
              <a className="link-search"></a>
            </li>
            <li>
              <a
                href="https://ae.studio/"
                target="_blank"
                rel="noreferrer"
                className="link-bag"
              ></a>
            </li>
          </ul>
        </nav>

        <div className="search-container hide">
          <div className="link-search"></div>
          <div className="search-bar">
            <form action="">
              <input type="text" placeholder="Search apple.com" />
            </form>
          </div>
          <div className="link-close"></div>

          <div className="quick-links">
            <h2>Quick Links</h2>
            <ul>
              <li>
                <a href="https://ae.studio/" target="_blank" rel="noreferrer">
                  Visiting an Apple Store FAQ
                </a>
              </li>
              <li>
                <a href="https://ae.studio/" target="_blank" rel="noreferrer">
                  Shop Apple Store Online
                </a>
              </li>
              <li>
                <a href="https://ae.studio/" target="_blank" rel="noreferrer">
                  Accessories
                </a>
              </li>
              <li>
                <a href="https://ae.studio/" target="_blank" rel="noreferrer">
                  AirPods
                </a>
              </li>
              <li>
                <a href="https://ae.studio/" target="_blank" rel="noreferrer">
                  AirTag
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="mobile-search-container">
          <div className="link-search"></div>
          <div className="search-bar">
            <form action="">
              <input type="text" placeholder="Search apple.com" />
            </form>
          </div>
          <span className="cancel-btn">Cancel</span>

          <div className="quick-links">
            <h2>Quick Links</h2>
            <ul>
              <li>
                <a href="https://ae.studio/" target="_blank" rel="noreferrer">
                  Visiting an Apple Store FAQ
                </a>
              </li>
              <li>
                <a href="https://ae.studio/" target="_blank" rel="noreferrer">
                  Shop Apple Store Online
                </a>
              </li>
              <li>
                <a href="https://ae.studio/" target="_blank" rel="noreferrer">
                  Accessories
                </a>
              </li>
              <li>
                <a href="https://ae.studio/" target="_blank" rel="noreferrer">
                  AirPods
                </a>
              </li>
              <li>
                <a href="https://ae.studio/" target="_blank" rel="noreferrer">
                  AirTag
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div id="under-menu-container">
        <div id="links-container">
          <div className="left-container">
            <span className="date">Available starting 3.18</span>
            <span className="title">Mac StudiO's</span>
          </div>
          <div className="right-container">
            <a href="https://ae.studio" target="_blank" rel="noreferrer">
              Overview
            </a>
            <a href="https://ae.studio" target="_blank" rel="noreferrer">
              Why Mac
            </a>
            <a href="https://ae.studio" target="_blank" rel="noreferrer">
              Tech Specs
            </a>
            <a
              href="https://ae.studio"
              className="order-now"
              target="_blank"
              rel="noreferrer"
            >
              Order now
            </a>
          </div>
        </div>
      </div>

      <div className="overlay"></div>
    </header>
  );
};

export default Header;
