const Wheatsup = () => {
  return (
    <div id="wheatsup-container">
      <h3>Wheat's up?</h3>
      <div className="text">
        Mac StudiO’s are an entirely new breakfast. They pack outrageous
        nutrients, extensive natural ingredients, and new capabilities into an
        unbelievably delicious form, putting everything you need within easy
        reach and transforming any meal into a true studiO. And it all starts
        with your choice of the ferociously tasty M1 Max or the all-new M1 Ultra
        — the most powerful meal ever outside of a lab.
      </div>
      <a href="https://ae.studio" target="_blank" rel="noreferrer">
        Learn the secret that unites these superpowers <div>+</div>
      </a>
    </div>
  );
};

export default Wheatsup;
