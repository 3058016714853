const Healthy = () => {
  return (
    <div id="healthy-container">
      <div className="image-container">
        <img
          src="https://applebreakfast.com/img/healthy.png"
          alt="Healthy, Wealthy, Wise."
        />
      </div>
      <div className="right-container">
        <h3>
          Healthy. <br /> Wealthy <br /> Wise.
        </h3>
        <div className="text">
          The best grains, from the very beginning. We’ve completely redefined
          what it means to eat cereal.
        </div>
      </div>
    </div>
  );
};

export default Healthy;
