const M1max = () => {
  return (
    <div id="m1max-container">
      <div className="content">
        <h1>M1 Max</h1>
        <div className="text">
          M1 Max brings power to tackle meals of almost any size. Whether you’re
          running a marathon, running a fortune 500 business, or just plain
          running late to a meeting with Jan in accounting, the
          delightfully-sweet M1 Max has your back.
        </div>
        <div className="speed-container">
          <div>
            <h5>Up to</h5>
            <h3>2.5x</h3>
            <h4>faster digestion</h4>
          </div>
          <div>
            <h5>Up to</h5>
            <h3>3.4x</h3>
            <h4>cleaner teeth</h4>
          </div>
          <div>
            <h5>Up to</h5>
            <h3>2.2x</h3>
            <h4>more recycled grains</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default M1max;
