const Weknow = () => {
  return (
    <div id="weknow-container">
      <h3>
        We know breakfast. <br /> You know us.
      </h3>
      <h3>Introductions all around.</h3>
      <h3 className="note">Note: Do not pour milk on your Mac.</h3>
      <img
        src="https://www.applebreakfast.com/img/final.jpg"
        alt="Mac Studio"
      />
    </div>
  );
};

export default Weknow;
