const M1ultra = () => {
  return (
    <div id="m1ultra-container">
      <div className="content">
        <h1>M1 Ultra</h1>
        <div className="text">
          Built from two M1 Max serving-sizes, M1 Ultra lets you power through
          workflows on an unprecedented scale. So you can run full marathons or
          work with coworkers that were previously impossible to deal with.
          Including half the sodium of competing cereals, M1 Ultra can support
          up to 18 streams of oat milk (or disgusting unethical dairy milk) at
          the same time. — something no other breakfast cereal can do.
        </div>
        <div className="speed-container">
          <div>
            <h5>Up to</h5>
            <h3>3.8x</h3>
            <h4>faster digestion</h4>
          </div>
          <div>
            <h5>Up to</h5>
            <h3>4.5x</h3>
            <h4>cleaner teeth</h4>
          </div>
          <div>
            <h5>Up to</h5>
            <h3>3.0x</h3>
            <h4>more recycled grains</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default M1ultra;
