const ChooseYourSuperpower = () => {
  return (
    <div id="choose-power-container">
      <h3>
        Choose your <br /> superpower.
      </h3>
      <div className="m1-container">
        <div>
          <img
            src="https://www.applebreakfast.com/img/m1max.png"
            alt="M1 Max"
          />
          <p>
            18oz Box <br /> Up to 3g of Soluble Fiber <br /> Can help lower
            cholesterol
          </p>
        </div>
        <div>
          <img
            src="https://www.applebreakfast.com/img/m1ultra.png"
            alt="M1 Ultra"
          />
          <p>
            30oz Box <br /> Up to 4g of Soluble Fiber <br /> 200mg of Caffeine
          </p>
        </div>
      </div>
    </div>
  );
};

export default ChooseYourSuperpower;
