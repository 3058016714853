const Footer = () => {
  const handleSdsLogoClick = () => {
    window.open("https://ae.studio/same-day-skunkworks");
  };
  return (
    <footer>
      <img
        src="https://ae.studio/img/same-day-skunkworks/watermark.png"
        alt="Same Day Skunkworks watermark"
        className="sds-watermark rotating"
        onClick={handleSdsLogoClick}
      />
      <div className="footer-content">
        <nav className="footer-menu">
          <div className="footer-col">
            <div className="item">
              <input type="checkbox" id="products" />
              <div className="col-section">
                <label htmlFor="products">
                  <h3>Shop and learn</h3>
                </label>
                <ul className="product-list">
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Mac
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      iPad
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      iPhone
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Watch
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      TV
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Music
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      iTunes
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      HomePod
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      iPod touch
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Accessories
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Gift Cards
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="footer-col">
            <div className="item">
              <input type="checkbox" id="store" />
              <div className="col-section">
                <label htmlFor="store">
                  <h3>Apple Store</h3>
                </label>
                <ul className="product-list">
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Find a Store
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Genius Bar
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Today at Apple
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Apple Camp
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Field Trip
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Apple Store App
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Refurbished and Clearance
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Financing
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Reuse and Recycling
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Order Status
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Shopping Help
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="footer-col">
            <div className="item">
              <input type="checkbox" id="education" />
              <div className="col-section">
                <label htmlFor="education">
                  <h3>For Education</h3>
                </label>
                <ul className="product-list">
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Apple and Education
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Shop for College
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="item item-padding-top">
              <input type="checkbox" id="business" />
              <div className="col-section">
                <label htmlFor="business">
                  <h3>For Business</h3>
                </label>
                <ul className="product-list">
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Apple and Business
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Shop for Business
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="footer-col">
            <div className="item">
              <input type="checkbox" id="acccount" />
              <div className="col-section">
                <label htmlFor="acccount">
                  <h3>Account</h3>
                </label>
                <ul className="product-list">
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Manage Your Apple ID
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Apple Store Account
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      iCloud.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="item item-padding-top">
              <input type="checkbox" id="values" />
              <div className="col-section">
                <label htmlFor="values">
                  <h3>Apple Values</h3>
                </label>
                <ul className="product-list">
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Accessibility
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Education
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Environment
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Inclusion and Diversity
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Supplier Responsibility
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="footer-col">
            <div className="item">
              <input type="checkbox" id="about" />
              <div className="col-section">
                <label htmlFor="about">
                  <h3>About Apple</h3>
                </label>
                <ul className="product-list">
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Newsroom
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Apple Leadership
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Job Opportunities
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Investors
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Events
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ae.studio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Contact Apple
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
        <section className="footer-base">
          <div className="footer-more-ways" x-ms-format-detection="none">
            More ways to shop: Visit an{" "}
            <a href="https://ae.studio" target="_blank" rel="noreferrer">
              Apple Store
            </a>
            ,{" "}
            <span className="nowrap">
              call 1-800-MY-APPLE, or{" "}
              <a href="https://ae.studio" target="_blank" rel="noreferrer">
                find a reseller
              </a>
            </span>
            .
          </div>
          <div className="footer-legal" style={{ textAlign: "right" }}>
            Made with ♥ by{" "}
            <a href="https://ae.studio" target="_blank" rel="noreferrer">
              Agency Enterprise
            </a>
          </div>
        </section>
      </div>
    </footer>
  );
};

export default Footer;
