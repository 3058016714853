import { useEffect, useState } from "react";
import "./App.css";
import AnimationContainer from "./components/AnimationContainer";
import ChooseYourSuperpower from "./components/ChooseYourSuperpower";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Healthy from "./components/Healthy";
import M1max from "./components/M1max";
import M1ultra from "./components/M1ultra";
import VideoContainer from "./components/VideoContainer";
import Weknow from "./components/Weknow";
import Wheatsup from "./components/Wheatsup";
import "./style.css";

function App() {
  const [shouldBeFixed, setShouldBeFixed] = useState(false);

  let counter = 0;
  useEffect(() => {
    if (counter > 0) return;
    counter++;
    const lottie = window.lottie;
    const ScrollTrigger = window.ScrollTrigger;
    const gsap = window.gsap;

    const ScrollLottie = (obj) => {
      let anim = lottie.loadAnimation({
        container: document.querySelector(obj.target),
        renderer: "svg",
        loop: false,
        autoplay: false,
        path: obj.path,
      });

      let timeObj = { currentFrame: 0 };
      ScrollTrigger.create({
        trigger: obj.target,
        scrub: true,
        pin: true,
        start: "-50",
        end: "+=2000",
        onUpdate: (self) => {
          if (obj.duration) {
            gsap.to(timeObj, {
              duration: obj.duration,
              currentFrame: Math.floor(self.progress * (anim.totalFrames - 1)),
              onUpdate: () => {
                anim.goToAndStop(timeObj.currentFrame, true);
              },
              ease: "expo",
            });
          } else {
            anim.goToAndStop(self.progress * (anim.totalFrames - 1), true);
          }
        },
      });
    };

    ScrollLottie({
      target: "#animationWindow",
      path: "https://www.applebreakfast.com/animation/data.json",
      duration: 0.1,
      speed: "fast",
    });

    let wordmark = document.querySelector("#video-bg-container");
    var lastTime = 0;
    var forward = true;

    function checkDirection() {
      var newTime = tl.time();
      if ((forward && newTime < lastTime) || (!forward && newTime > lastTime)) {
        forward = !forward;
        if (!forward) {
          onReverseStart();
        }
      }
      lastTime = newTime;
    }

    function onReverseStart() {
      gsap.set("body", { backgroundColor: "black" });
      gsap.set("#choose-power-container", { opacity: "0" });
      setShouldBeFixed(true);
    }

    const onTlComplete = () => {
      gsap.set(wordmark, { position: "relative" });
      gsap.set("body", { backgroundColor: "white" });
      gsap.set("#choose-power-container", { opacity: "1" });
      setShouldBeFixed(false);
    };

    const onTlStart = () => {
      gsap.set("body", { backgroundColor: "black" });
      gsap.set("#choose-power-container", { opacity: "0" });
      setShouldBeFixed(true);
    };

    const onTlReverseComplete = () => {
      gsap.set("body", { backgroundColor: "white" });
      setShouldBeFixed(false);
    };

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: document.querySelector("#ref"),
        start: "top bottom",
        end: "bottom top",
        scrub: true,
      },
      onReverseComplete: onTlReverseComplete,
      onStart: onTlStart,
      onComplete: onTlComplete,
      onUpdate: checkDirection,
    });

    tl.to(wordmark, {
      position: "fixed",
      transform: "scale(1)",
      opacity: "1",
      top: "0",
    });
  }, [setShouldBeFixed, counter]);

  return (
    <div className="App">
      <Header />
      <div className="content-container">
        <AnimationContainer />
        <div id="ref" style={{ height: "100vh" }}></div>
        <VideoContainer shouldBeFixed={shouldBeFixed} />
        <ChooseYourSuperpower />
        <Wheatsup />
        <Healthy />
        <M1max />
        <M1ultra />
        <Weknow />
        <Footer />
      </div>
    </div>
  );
}

export default App;
